import React from "react";
import Link from "gatsby-link";

interface Props {
  children: React.ReactNode;
  locale: string;

  to: string;
  event?: any;
  role?: string;

  active?: any;
  toggleMenu?: any;
}

export const LocalizedLink = ({
  children,
  locale,

  to,
  event,
  role,

  active,
  toggleMenu,
  ...rest
}: Props) => {
  const path = locale === "en" ? `${to}` : `${to}/${locale}/`;
  return (
    <Link to={path} onClick={event} role={role} {...rest}>
      {children}
    </Link>
  );
};

export default LocalizedLink;
