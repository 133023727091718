import * as React from "react";
import styled from "styled-components";

import { media } from "../../styles";

import { Grid } from "../../components";

import { ScreenshotsGallery } from "./ScreenshotsGallery";

type Screenshots = Images[];

interface Images {
  image: { childImageSharp: { fluid: { src: string; srcSet: string } } };
  small_image: {
    childImageSharp: { fluid: { src: string; srcSet: string } };
  };
  alt: string;
}

interface Props {
  gallery_title: string;
  gallery_subtitle: string;
  gallery_iphone_screenshots: Screenshots;
}

export const GallerySection = ({
  gallery_title,
  gallery_subtitle,
  gallery_iphone_screenshots,
}: Props) => {
  const setIphoneScreenshots = () => {
    let iphone_screenshots: any[] = [];

    gallery_iphone_screenshots.map(({ image, small_image, alt }: Images) => {
      let { src, srcSet } = image.childImageSharp.fluid;
      let thumbnail = small_image.childImageSharp.fluid.src;
      let thumbnailWidth = 261;
      let thumbnailHeight = 464;
      let newImage = {
        src,
        srcSet,
        alt,
        thumbnail,
        thumbnailWidth,
        thumbnailHeight,
      };

      iphone_screenshots.push(newImage);
    });

    return iphone_screenshots;
  };

  return (
    <Grid.Layout>
      <Section id="gallery">
        <Title>{gallery_title}</Title>
        <Subtitle>{gallery_subtitle}</Subtitle>
        <ScreenshotsGallery iphone_screenshots={setIphoneScreenshots()} />
      </Section>
    </Grid.Layout>
  );
};

// Styles for styled-components
const Section = styled.section`
  padding: 4rem 0 2.5rem;
  text-align: center;
  ${media.from.laptop`padding: 2.5rem 0 1.5rem;`};
`;

const Title = styled.h2`
  margin: 0 0 1rem;
  font-size: 1.5rem;
  font-weight: 600;
  text-transform: uppercase;

  ${media.from.laptop`font-size: 2rem;`};
`;

const Subtitle = styled.h3`
  margin: 0 0 1rem;
  font-size: 1rem;
  font-weight: 500;

  ${media.from.tablet`font-size: 1.25rem;`};
`;

export default GallerySection;
