import * as React from "react";
import styled from "styled-components";

import { media } from "../../styles";

import { Grid } from "../../components";

import FeaturesBlock from "./FeaturesBlock";

type Features = Feature[];

interface Feature {
  caption: string;
  description: string;
  icon: { childImageSharp: { fluid: { src: string } } };
  alt: string;
}

interface Props {
  title: string;
  subtitle: string;
  product_image: string;
  product_image_alt: string;
  description_part: string;
  features: Features;
}

export const ProductSection = ({
  title,
  subtitle,
  product_image,
  product_image_alt,
  description_part,
  features,
}: Props) => {
  return (
    <Grid.Layout>
      <Section>
        <Grid.Row>
          <Grid.Col lg={7} xl={6}>
            <ProductImageContainer>
              <ProductImage src={product_image} alt={product_image_alt} />
            </ProductImageContainer>
          </Grid.Col>
          <Grid.Col lg={5} xl={6}>
            <ProductDescription>
              <Title>{title}</Title>
              <SubTitle>{subtitle}</SubTitle>
              <ProductImageMobileContainer>
                <ProductImage src={product_image} alt={product_image_alt} />
              </ProductImageMobileContainer>
              <ProductImageTabletContainer>
                <ProductImage src={product_image} alt={product_image_alt} />
              </ProductImageTabletContainer>
              <ContentContainer>
                <Content>{description_part}</Content>
                <Link href={"http://www.eastcolight.com/"}>
                  <Button>Where to buy</Button>
                </Link>
              </ContentContainer>
            </ProductDescription>
          </Grid.Col>
          <Grid.Col lg={12} xl={12}>
            <FeaturesBlock features={features} />
          </Grid.Col>
        </Grid.Row>
      </Section>
    </Grid.Layout>
  );
};

// Styles for styled-components
const Section = styled.section`
  align-items: flex-end;
  display: flex;
  margin: 2.5rem 0 1rem;

  ${media.from.laptop`min-height: calc(100vh - 10rem);`};
`;

const ProductImageContainer = styled.div`
  align-content: center;
  justify-content: center;
  display: none;

  ${media.from.laptop`justify-content: flex-end; display: flex;`};
`;

const ProductImageMobileContainer = styled(ProductImageContainer)`
  display: flex;

  ${media.from.tablet`display: none;`};
`;

const ProductImageTabletContainer = styled(ProductImageContainer)`
  display: none;

  ${media.from.tablet`display: flex;`};

  ${media.from.laptop`display: none;`};
`;

const ProductImage = styled.img`
  width: 100%;
  height: 100%;
  min-height: 238px;

  ${media.from.tablet`width: 528px; height: 438px; min-height: 438px;`};
`;

const ProductDescription = styled.div`
  align-items: center;
  flex-direction: column;
  justify-content: center;
  display: flex;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;

  ${media.from.laptop`align-items: flex-start; margin: 0 0 0 1rem;`};
`;

const Title = styled.h1`
  padding: 0;
  margin: 0 0 1rem;
  font-size: 1.75rem;
  text-align: center;

  ${media.from.tablet`font-size: 2rem;`};

  ${media.from.laptop`font-size: 2.5rem; text-align: left;`};
`;

const SubTitle = styled.h3`
  padding: 0;
  margin: 0 0 1.5rem;
  font-size: 1.25rem;
  font-weight: 500;
  text-align: center;

  ${media.from.tablet`font-size: 1.5rem;`};

  ${media.from.laptop`margin: 0 0 1rem; font-weight: 600; text-align: left;`};
`;

const ContentContainer = styled.div`
  justify-content: center;
  display: flex;
  flex-direction: column;
  margin: 2rem 0 0.5rem;

  ${media.from.laptop`padding: 0 1rem 0 0; margin: 0; text-align: left;`};

  ${media.from.desktop`padding: 0 4.5rem 0 0;`};
`;

const Content = styled.p`
  padding: 0;
  margin: 0 0 1.5rem;
  font-size: 1rem;
  text-align: center;

  ${media.from.tablet`padding: 0 7rem;`};

  ${media.from.laptop`padding: 0; text-align: left;`};
`;

const Link = styled.a`
  margin: 0 auto;
  text-decoration: none;
  cursor: pointer;

  ${media.from.laptop`&:hover > button { background: #EE7D3F };  margin: 0;`};
`;

const Button = styled.button`
  padding: 0.75rem 2rem;
  background-color: #ff9500;
  font-size: 1.25rem;
  text-transform: uppercase;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
`;

export default ProductSection;
