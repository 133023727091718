import * as React from "react";
import styled from "styled-components";
import { ParallaxProvider, Parallax } from "react-scroll-parallax";

import { media } from "../styles";

import { Grid } from "../components";
import { LocalizedLink } from "../i18n/LocalizedLink";

interface Image {
  childImageSharp: { fluid: { src: string; srcSet: string } };
}

interface Dino {
  name: string;
  description?: string;
  shortDescription: string;
  image: Image;
  bigImage?: Image;
  alt: string;
  link: string;
}

type Dinos = Dino[];

interface Props {
  locale: string;
  activeDino?: string;

  dinos_title: string;
  dinos_image: {
    childImageSharp: {
      fluid: { originalImg: string; src: string; srcSet: string };
    };
  };
  dinos: Dinos;
}

export const DinosSection = ({
  locale,
  activeDino,

  dinos_title,
  dinos_image,
  dinos,
}: Props) => {
  const newDinos = activeDino
    ? dinos.filter(dino => dino.name !== activeDino)
    : dinos;

  return (
    <Section id="dinos">
      <ParallaxProvider>
        <Parallax
          className="custom-class"
          offsetYMax={-70}
          offsetYMin={70}
          tag="figure"
        >
          <ParallaxImage
            style={{
              backgroundImage: `url(${
                dinos_image.childImageSharp.fluid.originalImg
              })`,
            }}
          />
        </Parallax>
      </ParallaxProvider>
      <Background
        style={{
          backgroundImage: `url(${
            dinos_image.childImageSharp.fluid.originalImg
          })`,
        }}
      >
        <Overlay>
          <Grid.Layout>
            <SectionContainer>
              {activeDino == null && <Title>{dinos_title}</Title>}
              <DinosContainer>
                {newDinos.map(
                  ({ name, shortDescription, image, alt, link }: Dino) => (
                    <Dino key={name} to={link} locale={locale}>
                      <ImageContainer>
                        <Image
                          srcSet={image.childImageSharp.fluid.srcSet}
                          src={image.childImageSharp.fluid.src}
                          alt={alt}
                        />
                      </ImageContainer>
                      <Content>
                        <Name>{name}</Name>
                        <Description>{`${shortDescription}...`}</Description>
                      </Content>
                    </Dino>
                  )
                )}
              </DinosContainer>
            </SectionContainer>
          </Grid.Layout>
        </Overlay>
      </Background>
    </Section>
  );
};

// Styles for styled-components
const Section = styled.section`
  position: relative;
  overflow: hidden;
`;

const ParallaxImage = styled.img`
  display: none;
  background-size: auto;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 150%;

  ${media.from.laptop`display: block;`};
`;

const Background = styled.div`
  min-height: 500px;
  background-size: auto;
  background-position: center;
  background-repeat: repeat-y;

  background-size: cover;

  ${media.from.laptop`background-image: none !important;`};
`;

const Overlay = styled.div`
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7));

  ${media.from
    .laptop`background: linear-gradient(rgba(0, 0, 0, 0.7) 16px, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.3));`};
`;

const SectionContainer = styled.div`
  padding: 2.5rem 0 1.5rem;
  min-height: 500px;
  text-align: center;
`;

const Title = styled.h2`
  margin: 0 0 1.5rem;
  font-size: 1.75rem;
  font-weight: 600;
  text-transform: uppercase;
  color: #fff;

  ${media.from.laptop`font-size: 2.5rem;`};
`;

const DinosContainer = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  margin: 1rem 0;
`;

const Dino = styled(LocalizedLink)`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 334px;
  margin: 1.5rem;
  background: #fff;
  border-radius: 25px;
  overflow: hidden;
  text-decoration: none;

  ${media.from.tablet`min-height: 394px;`};

  ${media.from.laptop`min-height: 398px;`};
`;

const ImageContainer = styled.div`
  display: flex;
  width: 100%;
`;

const Image = styled.img`
  display: flex;
  width: 100%;
  height: 100%;
  min-width: 240px;
  min-height: 240px;
  max-width: 300px;
  margin: 0 auto;
  color: #000;

  ${media.from.tablet`min-height: 300px; min-width: 240px;`};
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  max-width: 300px;
  height: 100%;
  margin: 0.5rem 0 1.5rem;
  color: #000;
`;

const Name = styled.p`
  margin: 0 1rem;
  font-size: 1.25rem;
  font-weight: 600;

  ${media.from.laptop`font-size: 1.5rem;`};
`;

const Description = styled.p`
  margin: 0.5rem 1rem 0;
  font-size: 1rem;
`;

export default DinosSection;
