import * as React from "react";
import styled from "styled-components";

import { media } from "../styles";

import { Grid } from "../components";

interface Image {
  childImageSharp: { fluid: { src: string; srcSet: string } };
}

interface DinoProperties {
  name: string;
  value: string;
}

type DinosProperties = DinoProperties[];

interface Dino {
  name: string;
  description: string;
  shortDescription?: string;
  bigImage: Image;
  image?: Image;
  alt: string;
  properties: DinosProperties;
}

type Dinos = Dino[];

interface Props {
  activeDino: string;

  dinos: Dinos;
}

export const DinoSection = ({ activeDino, dinos }: Props) => {
  const dino = dinos.filter(dino => dino.name == activeDino);
  return (
    <Grid.Layout>
      <Section>
        <Grid.Row>
          <Grid.Col lg={7} xl={6}>
            <DinoImageContainer>
              <DinoImage
                src={dino[0].bigImage.childImageSharp.fluid.src}
                srcSet={dino[0].bigImage.childImageSharp.fluid.srcSet}
                alt={dino[0].alt}
              />
            </DinoImageContainer>
          </Grid.Col>
          <Grid.Col lg={5} xl={6}>
            <DinoDescription>
              <Title>{dino[0].name}</Title>
              <DinoImageMobileContainer>
                <DinoImage
                  src={dino[0].bigImage.childImageSharp.fluid.src}
                  srcSet={dino[0].bigImage.childImageSharp.fluid.srcSet}
                  alt={dino[0].alt}
                />
              </DinoImageMobileContainer>
              <ContentContainer>
                <Content>{dino[0].description}</Content>
                <PropertysContainer>
                  {dino[0].properties.map(({ name, value }: DinoProperties) => (
                    <Property key={name}>
                      <Name>{name}:</Name>
                      <Value>{value}</Value>
                    </Property>
                  ))}
                </PropertysContainer>
              </ContentContainer>
              <Link href={"http://www.eastcolight.com/"}>
                <Button>Where to buy</Button>
              </Link>
            </DinoDescription>
          </Grid.Col>
        </Grid.Row>
      </Section>
    </Grid.Layout>
  );
};

// Styles for styled-components
const Section = styled.section`
  align-items: flex-end;
  display: flex;
  margin: 2.5rem 0 3rem;

  ${media.from.laptop`margin: 6rem 0;`};
`;

const DinoImageContainer = styled.div`
  align-content: center;
  justify-content: center;
  display: none;
  min-height: 240px;

  ${media.from.tablet`min-height: 465px;`};

  ${media.from.laptop`justify-content: flex-end; display: flex;`};
`;

const DinoImageMobileContainer = styled(DinoImageContainer)`
  display: flex;

  ${media.from.laptop`display: none;`};
`;

const DinoImage = styled.img`
  width: 100%;
  height: 100%;

  ${media.from.tablet`width: 558px; height: 465px;`};
`;

const DinoDescription = styled.div`
  align-items: center;
  flex-direction: column;
  justify-content: center;
  display: flex;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;

  ${media.from.laptop`align-items: flex-start; margin: 0 0 0 1rem;`};
`;

const Title = styled.h1`
  padding: 0;
  margin: 0 0 1rem;
  font-size: 1.75rem;
  text-align: center;

  ${media.from.tablet`font-size: 2rem;`};

  ${media.from.laptop`font-size: 2.5rem; text-align: left;`};
`;

const ContentContainer = styled.div`
  justify-content: center;
  display: flex;
  flex-direction: column;
  margin: 1rem 0 0.5rem;

  ${media.from.tablet`padding: 0 7rem;`};

  ${media.from.laptop`padding: 0 1rem 0 0; margin: 0; text-align: left;`};

  ${media.from.desktop`padding: 0 4.5rem 0 0;`};
`;

const Content = styled.p`
  padding: 0;
  margin: 0 0 1rem;
  font-size: 1rem;
  text-align: left;
`;

const Link = styled.a`
  margin: 0 auto;
  text-decoration: none;
  cursor: pointer;

  ${media.from.laptop`&:hover > button { background: #EE7D3F };  margin: 0;`};
`;

const Button = styled.button`
  padding: 0.75rem 2rem;
  background-color: #ff9500;
  font-size: 1.25rem;
  text-transform: uppercase;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
`;

const PropertysContainer = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 0 1rem;
  padding: 0;
`;

const Property = styled.li`
  display: flex;
  flex-direction: row;
  margin: 0.5rem 0;
`;

const Name = styled.div`
  margin: 0 0.25rem 0 0;
  padding: 0;
  font-weight: 700;
`;

const Value = styled.div``;

export default DinoSection;
