import * as React from "react";
import { Helmet } from "react-helmet";

interface Props {
  googleAnalyticsId: string;
}

export const Analytics = ({ googleAnalyticsId }: Props) => {
  const gtagSnippet = `
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', '${googleAnalyticsId}');`;

  return (
    <Helmet>
      <script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsId}`}
      />
      <script>{gtagSnippet}</script>
    </Helmet>
  );
};

export default Analytics;
