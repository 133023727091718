import * as React from "react";

interface Props {
  children?: React.ReactNode;
  reverse?: boolean;
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
}

export function Grid({ children }: Props) {
  return <div>{children}</div>;
}

export namespace Grid {
  export const Container = ({ children }: Props) => {
    return <div className="container">{children}</div>;
  };

  export const Row = ({ reverse = false, children }: Props) => {
    return (
      <div className={reverse !== true ? "row" : "row reverse"}>{children}</div>
    );
  };

  export const Col = ({
    xs = 12,
    sm = 12,
    md = 12,
    lg = 12,
    xl = 12,
    reverse = false,
    children,
  }: Props) => {
    return (
      <div
        className={`col-xs-${xs} col-sm-${sm} col-md-${md} col-lg-${lg} col-xl-${xl} ${
          reverse !== true ? "col" : "col reverse"
        }`}
      >
        {children}
      </div>
    );
  };

  export const Layout = ({ children }: Props) => {
    return (
      <Container>
        <Row>
          <Col>{children}</Col>
        </Row>
      </Container>
    );
  };
}

export default Grid;
