import * as React from "react";
import styled from "styled-components";

import { media } from "../styles";

import { Grid } from "../components";

export const VideoSection = () => {
  return (
    <Grid.Layout>
      <Section>
        <Title>POWERFUL AND REALISTIC EXPERIENCE</Title>
        <VideoContainer>
          <iframe
            width="100%"
            height="100%"
            src="https://www.youtube-nocookie.com/embed/2YOzZ24IvlU"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          />
        </VideoContainer>
      </Section>
    </Grid.Layout>
  );
};

// Styles for styled-components
const Section = styled.section`
  margin: 3rem 0 2.5rem;
  text-align: center;

  ${media.from.laptop`margin: 2rem 0 2.5rem;`};
`;

const Title = styled.h2`
  margin: 0 0 2.5rem;
  font-size: 1.5rem;
  font-weight: 600;
  text-transform: uppercase;

  ${media.from.laptop`font-size: 2rem;`};
`;

const VideoContainer = styled.div`
  min-width: 288px;
  min-height: 153px;
  margin: 0 auto;

  > iframe {
    border: 0;
  }

  ${media.from.mediumPhone`width: 342px; height: 192px;`};

  ${media.from.largePhone`width: 392px; height: 220px;`};

  ${media.from.tablet`width: 525px; height: 295px;`};

  ${media.from.laptop`width: 850px; height: 472.5px;`};
`;

export default VideoSection;
