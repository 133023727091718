import { css } from "styled-components";

interface Devices {
  [key: string]: string;
  smallPhone: string;
  mediumPhone: string;
  largePhone: string;
  tablet: string;
  laptop: string;
  desktop: string;
}

// Constants for media queries
export const devices: Devices = {
  smallPhone: "320px",
  mediumPhone: "375px",
  largePhone: "425px",
  tablet: "768px",
  laptop: "992px",
  desktop: "1200px",
};

export const media = Object.keys(devices).reduce(
  (acc: any, label: string) => {
    const device = devices[label];

    acc.to[label] = (...placeholders: any[]) => {
      return css`
        @media (max-width: ${parseInt(device, 10)}px) {
          ${css.call(undefined, ...placeholders)};
        }
      `;
    };

    acc.from[label] = (...placeholders: any[]) => {
      return css`
        @media (min-width: ${parseInt(device, 10)}px) {
          ${css.call(undefined, ...placeholders)};
        }
      `;
    };

    return acc;
  },
  { to: {}, from: {} }
);
