import * as React from "react";

export const Fonts = () => {
  return (
    <>
      <link rel="preconnect" href="https://fonts.gstatic.com" />
      <style
        dangerouslySetInnerHTML={{
          __html: `
          @font-face {
            font-family: 'Exo 2';
            font-style: normal;
            font-weight: 600;
            src: local('Exo 2'), local('Exo2-Regular'), url(https://fonts.gstatic.com/s/exo2/v4/7cHmv4okm5zmbtYoK-4W4nIp.woff2) format('woff2');
            unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
          }
          @font-face {
            font-family: 'Exo 2';
            font-style: italic;
            font-weight: 600;
            src: local('Exo 2 Italic'), local('Exo2-Italic'), url(https://fonts.gstatic.com/s/exo2/v4/7cHov4okm5zmbtYtG-wc5VArlT8.woff2) format('woff2');
            unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
          }
          @font-face {
            font-family: 'Exo 2';
            font-style: normal;
            font-weight: 600;
            src: local('Exo 2 Medium'), local('Exo2-Medium'), url(https://fonts.gstatic.com/s/exo2/v4/7cHrv4okm5zmbt7bCPs7wH8Dnzcj.woff2) format('woff2');
            unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
          }
          @font-face {
            font-family: 'Exo 2';
            font-style: italic;
            font-weight: 600;
            src: local('Exo 2 Medium Italic'), local('Exo2-MediumItalic'), url(https://fonts.gstatic.com/s/exo2/v4/7cHtv4okm5zmbtYtEx8_8H0JmBUhfrE.woff2) format('woff2');
            unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
          }
          @font-face {
            font-family: 'Exo 2';
            font-style: normal;
            font-weight: 700;
            src: local('Exo 2 Semi Bold'), local('Exo2-SemiBold'), url(https://fonts.gstatic.com/s/exo2/v4/7cHrv4okm5zmbt73D_s7wH8Dnzcj.woff2) format('woff2');
            unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
          }
          @font-face {
            font-family: 'Exo 2';
            font-style: italic;
            font-weight: 700;
            src: local('Exo 2 Semi Bold Italic'), local('Exo2-SemiBoldItalic'), url(https://fonts.gstatic.com/s/exo2/v4/7cHtv4okm5zmbtYtEzM48H0JmBUhfrE.woff2) format('woff2');
            unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
          }
          @font-face {
            font-family: 'Exo 2';
            font-style: normal;
            font-weight: 700;
            src: local('Exo 2 Bold'), local('Exo2-Bold'), url(https://fonts.gstatic.com/s/exo2/v4/7cHrv4okm5zmbt6TDvs7wH8Dnzcj.woff2) format('woff2');
            unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
          }
          @font-face {
            font-family: 'Exo 2';
            font-style: italic;
            font-weight: 700;
            src: local('Exo 2 Bold Italic'), local('Exo2-BoldItalic'), url(https://fonts.gstatic.com/s/exo2/v4/7cHtv4okm5zmbtYtE1c58H0JmBUhfrE.woff2) format('woff2');
            unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
          }`,
        }}
      />
    </>
  );
};

export default Fonts;
