import React from "react";
import Gallery from "react-grid-gallery";
import styled from "styled-components";

export const ScreenshotsGallery = iphone_screenshots => {
  return (
    <GalleryContainer>
      <MobileGalleryContainer>
        <Gallery
          images={iphone_screenshots.iphone_screenshots}
          enableImageSelection={false}
          backdropClosesModal={true}
          preloadNextImage={true}
          rowHeight={240}
          maxRows={4}
          margin={4}
        />
      </MobileGalleryContainer>
      <TabletGalleryContainer>
        <Gallery
          images={iphone_screenshots.iphone_screenshots}
          enableImageSelection={false}
          backdropClosesModal={true}
          preloadNextImage={true}
          rowHeight={300}
          maxRows={3}
          margin={4}
        />
      </TabletGalleryContainer>
      <LaptopGalleryContainer>
        <Gallery
          images={iphone_screenshots.iphone_screenshots}
          enableImageSelection={false}
          backdropClosesModal={true}
          preloadNextImage={true}
          rowHeight={400}
          maxRows={2}
          margin={8}
        />
      </LaptopGalleryContainer>
      <DesktopGalleryContainer>
        <Gallery
          images={iphone_screenshots.iphone_screenshots}
          enableImageSelection={false}
          backdropClosesModal={true}
          preloadNextImage={true}
          rowHeight={464}
          maxRows={2}
          margin={8}
        />
      </DesktopGalleryContainer>
    </GalleryContainer>
  );
};

// Styles for styled-components
const GalleryContainer = styled.div`
  display: block;
  min-height: 1px;
  width: 100%;
  overflow: auto;
`;

const MobileGalleryContainer = styled.div`
  display: block;

  @media (min-width: 768px) {
    display: none;
  }
`;

const TabletGalleryContainer = styled.div`
  display: none;

  @media (min-width: 768px) and (max-width: 991px) {
    display: block;
  }
`;
const LaptopGalleryContainer = styled.div`
  display: none;

  @media (min-width: 992px) and (max-width: 1199px) {
    display: block;
  }
`;

const DesktopGalleryContainer = styled.div`
  display: none;

  @media (min-width: 1200px) {
    display: block;
  }
`;

export default ScreenshotsGallery;
