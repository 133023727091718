import * as React from "react";
import styled from "styled-components";

import { media } from "../styles";

import { Grid } from "../components";

interface Image {
  childImageSharp: { fluid: { src: string; srcSet: string } };
}

interface Item {
  key: number;
  caption: string;
  description: string;
  image: Image;
  alt: string;
}

type Items = Item[];

interface Props {
  items_title: string;
  items_image: Image;
  items_image_alt: string;
  items: Items;
  right?: any;
}

interface State {
  right: boolean;
}

export class InsideTheBoxSection extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      right: false,
    };

    this.isRight = this.isRight.bind(this);
  }

  isRight(key: number) {
    this.setState({
      right: key % 2 === 0 ? true : false,
    });
  }

  public render() {
    return (
      <Grid.Layout>
        <Section id="features">
          <Title>{this.props.items_title}</Title>
          <BigImage
            src={this.props.items_image.childImageSharp.fluid.src}
            srcSet={this.props.items_image.childImageSharp.fluid.srcSet}
            alt={this.props.items_image_alt}
          />
          <ItemsContainer>
            {this.props.items.map(
              ({ key, caption, description, image, alt }: Item) => (
                <Item key={key}>
                  <Grid.Row reverse={key % 2 === 0 ? true : false}>
                    <Grid.Col lg={6} xl={6}>
                      <MobileCaption>{caption}</MobileCaption>
                      <ImageContainer right={key % 2 === 0 ? true : false}>
                        <Image
                          srcSet={image.childImageSharp.fluid.srcSet}
                          src={image.childImageSharp.fluid.src}
                          alt={alt}
                        />
                      </ImageContainer>
                    </Grid.Col>
                    <Grid.Col lg={6} xl={6}>
                      <Content right={key % 2 === 0 ? true : false}>
                        <Caption>{caption}</Caption>
                        <Description>{description}</Description>
                      </Content>
                    </Grid.Col>
                  </Grid.Row>
                </Item>
              )
            )}
          </ItemsContainer>
        </Section>
      </Grid.Layout>
    );
  }
}

// Styles for styled-components
const Section = styled.section`
  margin: 3rem 0 1.25rem;
  text-align: center;

  ${media.from.laptop`margin: 1.25rem 0 0;`};
`;

const Title = styled.h2`
  margin: 0 0 2.5rem;
  font-size: 1.5rem;
  font-weight: 600;
  text-transform: uppercase;

  ${media.from.laptop`font-size: 2rem;`};
`;

const BigImage = styled.img`
  width: 100%;
  height: 100%;
  max-width: 746px;
  min-height: 160px;
  max-height: 552px;
  margin: 0 auto;

  ${media.from.laptop`min-height: 415px;`};
`;

const ItemsContainer = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  margin: 1.5rem 0;
`;

const Item = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  margin: 2.5rem 0;

  ${media.from.laptop`margin: 0;`};
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: ${(p: State) => (p.right ? "flex-start" : "flex-end")};
  width: 100%;
`;

const Image = styled.img`
  display: flex;
  width: 100%;
  height: 100%;
  max-width: 480px;
  max-height: 362px;
  min-height: 217px;
  margin: 0 auto;

  ${media.from.laptop`margin: 0; min-height: 361px;`};
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  height: 100%;
  padding: 0;

  @media (min-width: 768px) {
    padding: 0 7rem;
  }

  @media (min-width: 992px) {
    padding: ${(p: State) => (p.right ? "0 0 0 7.5rem" : "0 7.5rem 0 0")};
  }
`;

const MobileCaption = styled.p`
  display: block;
  margin: 0;
  font-size: 1.25rem;
  font-weight: 600;

  ${media.from.laptop`display: none;`};
`;

const Caption = styled.p`
  display: none;
  margin: 0 0 0.25rem;
  font-size: 1.5rem;
  font-weight: 600;

  ${media.from.laptop`display: block;`};
`;

const Description = styled.p`
  margin: 0;
  font-size: 1rem;
`;

export default InsideTheBoxSection;
