import * as React from "react";
import { Helmet } from "react-helmet";

interface Props {
  title: string;
  description: string;
  appleAppId: string;
}

export const OpenGraph: React.StatelessComponent<Props> = ({
  title,
  description,
  appleAppId,
}: Props) => {
  return (
    <Helmet>
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content="AR Dino World" />
      <meta property="og:url" content={"https://monsterparkbox.com"} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />

      <meta
        property="og:image"
        content={`${
          title == "AR Dino World"
            ? "https://monsterparkbox.com/card.jpg"
            : `https://monsterparkbox.com/${title}Card.jpg`
        } `}
      />
      <meta
        property="og:image:secure_url"
        content={`${
          title == "AR Dino World"
            ? "https://monsterparkbox.com/card.jpg"
            : `https://monsterparkbox.com/${title}Card.jpg`
        } `}
      />
      <meta property="og:image:type" content="image/jpeg" />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta property="og:image:alt" content={description} />

      <meta name="apple:content_id" content={appleAppId} />

      <link rel="apple-touch-icon" href="/touch-icon-iphone.png" />
      <link
        rel="apple-touch-icon"
        sizes="152x152"
        href="/touch-icon-ipad.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/touch-icon-iphone-retina.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="167x167"
        href="/touch-icon-ipad-retina.png"
      />
    </Helmet>
  );
};

export default OpenGraph;
