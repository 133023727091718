import * as React from "react";
import styled from "styled-components";

import { media } from "../../styles";

// import MobileNav from "./MobileNav";
import { LocalizedLink } from "../../i18n/LocalizedLink";

interface Link {
  caption: string;
  link: string;
}

type Navigation = Link[];

interface Props {
  locale: string;

  active?: any;
  toggleMenu?: any;

  title?: string;
  logo_alt?: string;
  logo_icon?: string;
  navigation?: Navigation;
}

export const Header = ({
  locale,

  // active,
  // toggleMenu,

  title,
  logo_alt,
  logo_icon,
}: // navigation,
Props) => {
  return (
    <header>
      <Container>
        <HeaderSection>
          <LogoLink to="/" locale={locale}>
            <Logo src={logo_icon} alt={logo_alt} />

            <Title>{title}</Title>
          </LogoLink>
          {/* <Nav>
            {navigation &&
              navigation.map(({ caption, link }: Link) => (
                <NavLinkInternal
                  key={caption}
                  to={link}
                  locale={locale}
                  role="button"
                  active={active}
                >
                  {caption}
                </NavLinkInternal>
              ))}
          </Nav>
          <BurgerMenu
            className={active ? "active" : ""}
            onClick={toggleMenu}
            role="button"
          >
            <Span />
            <Span />
            <Span />
            <Span />
          </BurgerMenu> */}
        </HeaderSection>
        {/* <MobileNav
          locale={locale}
          active={active}
          toggleMenu={toggleMenu}
          navigation={navigation}
        /> */}
      </Container>
    </header>
  );
};

// Styles for styled-components
const Container = styled.div`
  min-height: 3rem;
  padding: 1.5rem 0;
`;

const HeaderSection = styled.div`
  position: relative;
  justify-content: center;
  display: flex;
  z-index: 100;

  ${media.from.laptop`justify-content: space-between;`};
`;

const LogoLink = styled(LocalizedLink)`
  align-items: center;
  display: flex;
  text-decoration: none;
`;

const Logo = styled.img`
  width: 48px;
  height: 48px;
  border-radius: 10px;
`;

const Title = styled.p`
  margin: 0 1rem;
  color: #000;
  font-size: 1.15rem;
  font-weight: 600;
  letter-spacing: 1px;
  /* text-transform: uppercase; */
`;

// const Nav = styled.nav`
//   display: none;

//   ${media.from.tablet`display: flex;`};
// `;

// const NavContainer = styled.div`
//   align-items: center;
//   display: flex;
//   margin: 0 1rem;
// `;

// const NavLinkInternal = styled(LocalizedLink)`
//   color: #000;
//   font-size: 1rem;
//   font-weight: 600;
//   text-decoration: none;
//   border-bottom: 2px solid transparent;
//   align-items: center;
//   display: flex;
//   margin: 0 1rem;

//   ${media.from.laptop`&:hover { border-bottom: 2px solid #000; }`};
// `;

// const NavLinkExternal = styled.a`
//   color: #000;
//   font-size: 1rem;
//   font-weight: 600;
//   text-decoration: none;
//   border-bottom: 2px solid transparent;

//   ${media.from.laptop`&:hover { border-bottom: 2px solid #000; }`};
// `;

// const BurgerMenu = styled.div`
//   position: relative;
//   display: flex;
//   width: 24px;
//   height: 20px;
//   margin: auto 0;
//   cursor: pointer;

//   ${media.from.tablet`display: none;`};
// `;

// const Span = styled.span`
//   position: absolute;
//   left: 0;
//   display: block;
//   width: 100%;
//   height: 3px;
//   background: #000;
//   transform: rotate(0deg);
//   transition: 0.25s ease-in-out;
//   opacity: 1;

//   &:nth-child(1) {
//     top: 0;
//     opacity: 1;
//   }

//   &:nth-child(2),
//   &:nth-child(3) {
//     top: 40%;
//   }

//   &:nth-child(4) {
//     top: 80%;
//     opacity: 1;
//   }

//   .active &:nth-child(1) {
//     top: 50%;
//     left: 50%;
//     width: 0%;
//     opacity: 0;
//   }

//   .active &:nth-child(2) {
//     transform: rotate(45deg);
//   }

//   .active &:nth-child(3) {
//     transform: rotate(-45deg);
//   }

//   .active &:nth-child(4) {
//     top: 50%;
//     left: 50%;
//     width: 0%;
//     opacity: 0;
//   }
// `;

export default Header;
