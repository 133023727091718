import * as React from "react";
import styled from "styled-components";

import { media } from "../../styles";

import { Grid } from "../../components";

type Features = Feature[];

interface Feature {
  caption: string;
  description: string;
  icon: { childImageSharp: { fluid: { src: string } } };
  alt: string;
}

interface Props {
  features: Features;
}

export const FeaturesBlock = ({ features }: Props) => {
  return (
    <Grid.Layout>
      <Block>
        <FeaturesContainer>
          {features.map(({ caption, description, icon, alt }: Feature) => (
            <Feature key={icon.childImageSharp.fluid.src}>
              <Image src={icon.childImageSharp.fluid.src} alt={alt} />
              <Caption>{caption}</Caption>
              <Description>{description}</Description>
            </Feature>
          ))}
        </FeaturesContainer>
      </Block>
    </Grid.Layout>
  );
};

// Styles for styled-components
const Block = styled.div`
  margin: 2rem 0 0;
  text-align: center;
`;

const FeaturesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  justify-content: center;
  flex-direction: row;
`;

const Feature = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1rem 0.75rem;
  max-width: 160px;

  ${media.from.desktop`max-width: 170px; margin: 1rem 1.25rem;`};
`;

const Image = styled.img`
  width: 96px;
  height: 96px;
  min-height: 96px;
  margin: 0 0 1rem;

  ${media.from.desktop`width: 120px; height: 120px; min-height: 120px;`};
`;

const Caption = styled.p`
  margin: 0 0 0.25rem;
  font-size: 1.25rem;
  font-weight: 600;
`;

const Description = styled.p`
  margin: 0;
  font-size: 1rem;
`;

export default FeaturesBlock;
