import * as React from "react";
import Helmet from "react-helmet";
import styled from "styled-components";

import "../styles/grid.css";
import "../styles/index.css";

import { Grid, Fonts, Header } from "../components";

interface Link {
  caption: string;
  link: string;
}

type Navigation = Link[];

interface Props {
  children?: React.ReactNode;
  locale: string;

  active?: any;

  meta_description?: string;
  meta_keywords?: string;

  header_title?: string;
  header_logo_alt?: string;
  header_logo_icon?: string;
  header_navigation?: Navigation;
}

interface State {
  menuActive: boolean;
}

export class Layout extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      menuActive: false,
    };

    this.toggleMenu = this.toggleMenu.bind(this);
  }

  toggleMenu(isVisible: boolean) {
    this.setState({
      menuActive:
        typeof isVisible === "undefined" ? !this.state.menuActive : isVisible,
    });
  }

  public render() {
    const isVisible = "undefined";
    const { locale } = this.props;
    const active = this.state.menuActive;
    const {
      meta_description,
      meta_keywords,
      header_title,
      header_logo_alt,
      header_logo_icon,
      header_navigation,
    } = this.props;

    let langLocale = "en";

    return (
      <Container active={active} locale={locale}>
        <Content>
          <Helmet>
            <html lang={langLocale} prefix="og: http://ogp.me/ns#" />
            <meta name="description" content={meta_description} />
            <meta name="keywords" content={meta_keywords} />
          </Helmet>
          <Fonts />
          <Grid.Layout>
            <Header
              active={active}
              toggleMenu={() =>
                this.toggleMenu(isVisible === "undefined" ? !active : active)
              }
              locale={locale}
              title={header_title}
              logo_alt={header_logo_alt}
              logo_icon={header_logo_icon}
              navigation={header_navigation}
            />
          </Grid.Layout>
          {this.props.children}
        </Content>
      </Container>
    );
  }
}

// Styles for styled-components
const Container = styled.div`
  position: ${(p: Props) => (p.active ? "fixed" : "relative")};
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
`;

const Content = styled.div`
  position: relative;
`;

export default Layout;
