import * as React from "react";
import styled from "styled-components";

import { media } from "../styles";

import { Grid } from "../components";

interface Props {
  footer_privacy: string;
  footer_terms: string;
}

export const Footer = ({ footer_privacy, footer_terms }: Props) => {
  return (
    <FooterContainer>
      <Grid.Layout>
        <Margins>
          <LinksContainer>
            <Link href="http://vitotechnology.com/privacy-policy.html">
              {footer_privacy}
            </Link>
            <Link href="http://vitotechnology.com/terms-of-use.html">
              {footer_terms}
            </Link>
          </LinksContainer>
          <Copyright>
            &copy; {new Date().getFullYear()} Vito Technology, Inc.
          </Copyright>
        </Margins>
      </Grid.Layout>
    </FooterContainer>
  );
};

// Styles for styled-components
const FooterContainer = styled.footer`
  background-color: #fff;
`;

const Margins = styled.div`
  margin: 1.5rem 0;
`;

const LinksContainer = styled.div`
  justify-content: center;
  display: flex;
  margin: 0 0 1.5rem;
`;

const Link = styled.a`
  margin: 0 0.5rem 0;
  color: #000;
  font-size: 0.75rem;
  text-align: center;
  text-decoration: none;

  ${media.from.laptop`&:hover { text-decoration: underline; }`};
`;

const Copyright = styled.div`
  color: #acacac;
  font-size: 0.75rem;
  text-align: center;
`;

export default Footer;
